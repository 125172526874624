<template>
  <div>
    <div class="Section" v-wechat-title="this.clickVal+ '-药百万'">
      <div class="Storebg">
        <img src="../assets/StoreListBg.png" alt="">
      </div>
      <!-- <div class="breadcrumbNav">
        <span class="navName">当前位置 : </span>
        <div class="activeNav">
          <span>
            <router-link to="/home"
            >{{ clickVal }} </router-link>
          </span>
        </div>
      </div> -->
        <div class="NullBox" v-if="ShopList.length==0">
          
        </div>
        <div class="StoreBox" v-else>
            <div class="StoreMain"  
              v-for="(item, index) in ShopList"
              :key="index"
              @click="JumpStore(item.postId)"
            >
                <div class="StoreLeft">
                    <img :src="item.postPicture" alt="">
                </div>     
                <div class="StoreRight">
                    <p>{{item.postAlias}}</p>
                    <p>上架<b>{{item.count}}</b>种商品 | 满{{item.postage}}免运费</p>
                </div>
            </div> 
             <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
  </div>
</template>

<script>
// const shopItem = () => import("components/index/shopItem.vue");
import {ShopList} from "api/home.js";
export default {
  name: "ShopList",
   inject:['reload'],
  data() {
    return {
       clickVal:'', //  面包屑 初始，一级分类选中值为空
      clickID: '',    //一级分类ID 
      ShopList: [], //初始店铺
     

    };
  },
  computed: {},
  created() {
                this.getShopList()

  },
  methods: {
        JumpStore(postId){
          console.log('postId',postId)
          this.$router.push({ name: "StorePage", query: { postId: postId}})
        },
        //  获取店铺列表
        getShopList(){
            // 显示loading
            this.$loading({ fullscreen: true ,background:'#ffffff45'})
                ShopList().then(res=>{
                    const records = res.data.data;
                    console.log(records)

                     this.clickVal = "店铺列表";
                    // this.clickID=res.data.data.id
                    this.ShopList = records;
                        // 关闭loading
                    this.$loading().close();
                })
        },

   
  },
  components: {
    // shopItem
  },

  mounted() {},
  updated() {},
};
</script>
<style>
.clear{
  clear: both;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ed3129;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #ed3129;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}

@import "~style/index.less";
/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.Section {
  // width: 1200px;
   width:100%;
  margin: 0px auto 0;
  // margin-top: 10px;
  background: #F7F7F7 ;
  padding-bottom: 40px;
  overflow: hidden;
  position: relative;
  .Storebg{
    img{
      width: 100%;
      height: auto;
    }
  }
  .breadcrumbNav {
    padding: 8px 15px 15px 0;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 20px;
    .navName {
      float: left;
      color: #999;
      padding-right: 8px;
    }
    .activeNav {
      float: left;
      color: #999;
      line-height: 20px;
      span {
        color: #551a8b;
        padding-right: 8px;
        float: left;
      }
      b {
        float: left;
      }
      .checkUl {
        float: left;
        padding-left: 8px;
        li {
          padding: 0 5px;
          height: 20px;
          line-height: 18px;
          border: 1px solid #ff9999;
          color: #ff9999;
          font-size: 12px;
          background: #fff;
          margin-right: 8px;
          float: left;
        }
      }
    }
  }
  .sortBox {
    box-sizing: content-box;
    background-color: #ffffff;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    width: 100%;
    float: left;
    .sortUlbox:last-child {
      border-bottom: none;
    }
    .sortUlbox {
      width: 100%;
      padding: 8px 15px;
      float: left;
      line-height: 40px;
      border-bottom: 1px dashed #ccc;
      .ulTitle {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        width: 136px;
        text-align: center;
        float: left;
      }
      .sortUl {
        width: calc(100% - 136px);
        float: left;
        li {
          float: left;
          margin-right: 40px;
          color: #666;
          font-size: 14px;
            cursor: pointer;
            &:hover{
                color: @theme;
            }
        }
        li.active {
          color: @theme;
        }
      }
    }
  }

  .NullBox{
      position: absolute;
      top: 140px;
      width: 100%;
      font-size: 30px;
      text-align: center;
      width: 100%;
      display:block;
      line-height: 300px;
       height: 300px;
      background:url("data:image/svg+xml,%3Csvg width='130' height='130' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill='%23a2a9b6' font-family='system-ui, sans-serif' text-anchor='middle' transform='rotate(-45, 100 100)' dominant-baseline='middle'%3E药百万%3C/text%3E%3C/svg%3E");
  }
  .StoreBox {
    width: 1200px;
    margin: 0 auto;
    height: auto;
     margin-top: -200px;
     padding-bottom: 100px;
     position: relative;
    // overflow: hidden;
     .StoreMain{
        width:calc(49% - 50px);
        margin-bottom:20px;
        background: #fff;
        padding:25px 25px;
        cursor: pointer;
        &:nth-child(odd){
           margin-right:1%;
        }
          &:nth-child(even){
          margin-left:1%;
        }
        float: left;
         .StoreLeft{
             float: left;
             img{
                    margin: 20px 20px 0 0;
                    width: 84px;
                    height: 84px;
             }
         }
         .StoreRight{
              float: left;
             margin-top:20px;
             p:nth-child(1){
                 color: #333333;
                 font-size:22px;
                 line-height: 50px;
                 font-weight: bold;
             }
             p:nth-child(2){
                 color: #666;
                    font-size:16px;
                 line-height:30px;
                 b{
                  color: #F43C38;
                  font-weight: bold;
                  padding: 0 6px;
                 }
             }
         }
     }
    
  }
}
</style>
